import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DisplayInfo from "../../components/Base/DisplayInfo";
import { Col, Row } from "antd";
import { formatCPFCNPJ, maskIntNumber } from "../../util/Utils";
import api from "../../services/api";
import Loading from "../../components/Base/Loading";

const TabContainer = styled.div`
  padding-top: 24px;
`;

export default function TabEquipmentClientDetail({
  clientId,
  equipment,
  isJohnDeere,
}) {
  const [isLoadingClient, setIsLoadingClient] = useState(false);
  const [client, setClient] = useState({});
  useEffect(() => {
    if (clientId) {
      fetchClient();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, isJohnDeere]);
  const fetchClient = async () => {
    try {
      setIsLoadingClient(true);
      if (isJohnDeere) {
        const response = await api.get(`client/${clientId}`);
        setClient(response.data);
        return;
      }
      const response = await api.get(`sap/client/${clientId}`);
      setClient(response.data);
    } catch (error) {
      // BuildFieldErrorsDict(error, null, false)
    } finally {
      setIsLoadingClient(false);
    }
  };

  const getIdLabel = () => {
    if (isJohnDeere) {
      return "id";
    }
    return "id (sap)";
  };

  const getNameLabel = () => {
    if (isJohnDeere) {
      return "Cliente";
    }
    return "Cliente (SAP)";
  };

  return (
    <TabContainer>
      {isLoadingClient ? (
        <Col xs={24}>
          <Loading />
        </Col>
      ) : (
        <Row gutter={[16, 16]}>
          <Col xs={12} md={8}>
            <DisplayInfo label={getIdLabel()}>{client?.id}</DisplayInfo>
          </Col>

          <Col xs={12} md={8}>
            <DisplayInfo label={getNameLabel()}>{client?.name}</DisplayInfo>
          </Col>

          <Col xs={12} md={8} hidden={isJohnDeere}>
            <DisplayInfo label={"Cliente (CARETRACK)"}>
              {equipment?.name}
            </DisplayInfo>
          </Col>
          <Col xs={12} md={8} hidden={isJohnDeere}>
            <DisplayInfo label={"cpf/cnpj"}>
              {formatCPFCNPJ(client?.cnpj_cpf)}
            </DisplayInfo>
          </Col>
          <Col xs={12} md={8} hidden={isJohnDeere}>
            <DisplayInfo label={"cidade/estado"}>
              {client?.city} / {client?.state}
            </DisplayInfo>
          </Col>
          <Col xs={12} md={8} hidden={isJohnDeere}>
            <DisplayInfo label={"centro"}>{client?.center}</DisplayInfo>
          </Col>
          <Col xs={12} md={8} hidden={isJohnDeere}>
            <DisplayInfo label={"Consultor de peças"}>
              {client?.parts_consultant}
            </DisplayInfo>
          </Col>
          <Col xs={12} md={8} hidden={isJohnDeere}>
            <DisplayInfo label={"Saldo do Limite"} showZero>
              R$ {maskIntNumber(client?.limit_value)}
            </DisplayInfo>
          </Col>
          <Col xs={12} md={8} hidden={isJohnDeere}>
            <DisplayInfo label={"Bloqueio FINANCEIRO"}>
              {client?.financial_block ? "Sim" : "Não"}
            </DisplayInfo>
          </Col>
        </Row>
      )}
    </TabContainer>
  );
}
