import React, {useState, useEffect} from 'react';
import PageTitle from "../../../components/Base/PageTitle";
import FingerScroll from "../../../components/Base/FingerScroll";
import InfoCard from "../../../components/Base/display/InfoCard";
import {maskIntNumber} from "../../../util/Utils";
import {Col, Divider, Button, Row} from "antd";
import {ListTimelineCard} from "../../../components/TimelineCard";
import OcurrenceCard from "../../../components/Opportunity/OccurrencesCard";
import RuleContainerInfo from "./RuleContainerInfo";
import PageText from "../../../components/Base/display/PageText";
import DividerPage from "../../../components/Base/DividerPage";
import {
    SYSTEM_CARETRACK_ID,
    SYSTEM_REMOTE_LOG_ID
} from "../../../util/OpportunityUtils";
import Loading from "../../../components/Base/Loading";
import styled from "styled-components";
import { content_bg } from "../../../Cores";
import { PlusOutlined } from "@ant-design/icons";
import api from "../../../services/api";
import CreateCotacaoOsModal from "../modals/CreateCotacaoOsModal";
import {Notification} from "../../../notification";

const Container = styled.div`
  display: flex;
  flex-direction: column;

  background: ${content_bg};
  width: 100%;
  height: 100%;
  padding: 16px;
  ${({ noHorizontalPadding }) => {
    if (noHorizontalPadding) {
      return `padding: 16px 0px`;
    }
  }}
`;

export default function CodeErrorOpportunity({opportunity, equipment}) {
    const telemetric_data = opportunity.telemetric_data
    const telemetric_system= opportunity.telemetry_system

    const [response, setResponse] = useState("");
    const [isLoading, setIsLoading] = useState();
    const [openCreateCotacaoOs, setOpenCreateCotacaoOs] = useState(false);
    const [client, setClient] = useState({});
    const [buttonIsDisabled, setButtonIsDisabled] = useState(false);
    const [textDescricao, setTextDescricao] = useState();

    useEffect(() => {
        if (equipment.client.id) {
          fetchClient();
        }
      }, [equipment.client.id]); // eslint-disable-line react-hooks/exhaustive-deps
    
      const fetchClient = async () => {
        try {
          const response = await api.get(`sap/client/${equipment.client.id}`);
          setClient(response.data);
        } catch (error) {
            console.log(error);
        }
      };

    function callIA(){
        setIsLoading(true)
        setResponse("")
        const options = {
            method: 'POST',
            headers: ({'content-type': 'application/json'}),
            body: JSON.stringify({user_request: `PID ${telemetric_data?.pid}, MID ${telemetric_data?.mid}, FMI ${telemetric_data?.fmi}`})
        }
      
        fetch('https://uptimeia.tracbel.com.br:8000/api/usr_request/', options)
        .then(function(T) {
            T.json().then(function(data) {
                setIsLoading(false)
                setResponse(data.resposta.replace(/(?:\r\n|\r|\n)/g, '<br/>'))
                const inicio = data.resposta.indexOf('"')
                if (inicio > 0){
                    const fim = data.resposta.indexOf('"', inicio + 2)
                    setTextDescricao(data.resposta.substring(inicio+1, fim))
                }
            }).finally(function(data){
                setIsLoading(false);
            })
        })
    }

    function dadosSalvos(e){
        let retorno = e();
        setButtonIsDisabled(true)
        Notification('success', `Sua solicitação técnica foi aberta sob o número ${retorno.budget.id_sap}`)
    }

    return (<>
        <PageTitle value={'Dados da notificação'}/>
        <FingerScroll>
            {SYSTEM_CARETRACK_ID === telemetric_system.id && <>
                <InfoCard
                    width={212}
                    data={maskIntNumber(telemetric_data?.mid)}
                    title={'MID'}/>
                <InfoCard
                    width={212}
                    data={maskIntNumber(telemetric_data?.pid)}
                    title={'PID'}/>
                <InfoCard
                    width={212}
                    data={maskIntNumber(telemetric_data?.pid_type)}
                    title={'TIPO PID'}/>
                <InfoCard
                    width={212}
                    data={maskIntNumber(telemetric_data?.fmi)}
                    title={'FMI'}/>
                <InfoCard
                    width={212}
                    data={maskIntNumber(telemetric_data?.acd_number)}
                    title={'Número do ACD'}/>
            </>}
            {SYSTEM_REMOTE_LOG_ID === telemetric_system.id  && <>
                <InfoCard
                    width={212}
                    data={maskIntNumber(opportunity.content_object.spn)}
                    title={'SPN'}/>
                <InfoCard
                    width={212}
                    data={maskIntNumber(opportunity.content_object.fmi)}
                    title={'FMI'}/>

            </>}
        </FingerScroll>
        <Divider style={{marginTop: 16}}/>
        <Col xs={24} md={24}>
            <PageTitle value={'Descrição'}
                       style={{marginTop: 15}}/>
            {SYSTEM_CARETRACK_ID === telemetric_system.id && <PageText value={telemetric_data.description}/>}
            {SYSTEM_REMOTE_LOG_ID === telemetric_system.id && <PageText value={opportunity.content_object?.caution_description}/>}
            <PageTitle value={'Ocorrências'}
                       style={{marginTop: 24}}/>
            <ListTimelineCard>
                <OcurrenceCard event={opportunity.ocurrences}/>
            </ListTimelineCard>
        </Col>
        <DividerPage/>
        <RuleContainerInfo opportunity={opportunity}/>
        <DividerPage/>
        <Row gutter={[24, 16]} align={'middle'} >
            <Col style={{textAlign: 'middle', marginTop: '10px'}}
                         xs={{span: 24, order: 1}}
                         lg={{span: 5, order: 3}}>
                <Button onClick={() => callIA()}
                                    type="primary">
                                Consultar especialista técnico virtual - IA
                </Button> 
            </Col>
        </Row>

        {isLoading  && <Loading noHeight/>}
        {response !== "" ? <>
                <PageTitle value='Resposta do especialista técnico virtual - IA:'
                        style={{marginTop: 24}}/>
                <p dangerouslySetInnerHTML={{__html: response}} style={{marginLeft: 30}}></p>
                <Container>
                    <Button
                        onClick={() => setOpenCreateCotacaoOs(true)}
                        type="primary"
                        disabled={buttonIsDisabled}
                        style={{ marginLeft: "auto", marginTop: "1rem" }}
                    >
                        <PlusOutlined />
                        Abrir solicitação técnica
                    </Button>
                </Container>
                <CreateCotacaoOsModal
                    client={client}
                    opportunity={opportunity}
                    setOpportunity={dadosSalvos}
                    setClose={setOpenCreateCotacaoOs}
                    isModalOpen={openCreateCotacaoOs}
                    textoDescricao={textDescricao}
                />
            </>
            : null
        }
    </>)
}