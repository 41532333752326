export const text_color = 'rgba(255, 255, 255, 0.85)';
export const text_color_inverse = 'rgba(0, 0, 0, 0.85)';
export const clear_icon = 'rgba(255, 255, 255, 0.4)';
export const gray_1 = 'rgba(255, 255, 255, 0.4)';
export const input_border = '#656C70';
export const gray = 'rgba(255, 255, 255, 0.45)';
export const gray_2 = 'rgba(255, 255, 255, 0.5)';
export const gray_dark = 'rgba(133, 141, 147, 1)';
export const grey_border = 'rgba(255, 255, 255, 0.2)';

export const icon_color = input_border;
export const danger_color_bg = '#5C0011';
export const danger_color_icon = '#FFA39E';
export const danger_color_shadow_light = 'rgba(255, 77, 79, 0.6)';
export const danger_color_shadow_dark = 'rgba(255, 77, 79, 0.3)';
export const collapse_header_text_color = 'rgba(255, 255, 255, 0.5)';
export const table_header = '#222425';
export const border_gray = 'rgba(255, 255, 255, 0.08)';



export const actionbar_bg = 'rgba(22, 23, 24, 1)';
export const chart_color_gray = '#858D93';
export const menu_bg = '#292C2E';
export const content_bg = '#1B1D1D';
export const primary_color = '#FDBA12';
export const primary_color_shadow_light = 'rgba(253, 186, 18, 0.6)';
export const primary_color_shadow_dark = 'rgba(253, 186, 18, 0.3)';
export const progress = '#36CFC9';
export const progress_bg = '#002329';
export const progress_border = '#006D75';


export const green_8 = '#B7EB8F';




export const Conditional = 'rgba(41, 44, 46, 1)';


export const primary_1 = 'rgba(34, 36, 37, 1)';


export const neutral_1 = 'rgba(27, 29, 29, 1)';
export const neutral_4 = '#3B4145';
export const neutral_5 = 'rgba(86, 93, 98, 1)';
export const gray_10 = '#262626';

export const cyan_1 = 'rgba(0, 35, 41, 1)';
export const cyan_2 = '#00474F';
export const cyan_3 = 'rgba(0, 109, 117, 1)';
export const cyan_6= 'rgba(54, 207, 201, 1)';
export const cyan_9= '#B5F5EC';

export const geek_blue_1 = 'rgba(27, 29, 29, 1)';
export const geek_blue_3 = 'rgba(16, 35, 158, 1)';
export const geek_blue_6= 'rgba(89, 126, 247, 1)';
export const blue_8 = '#ADC6FF';
export const day_break_blue_2= '#BAE7FF';
export const day_break_blue_9= '#003A8C';


export const mangeta_1= 'rgba(82, 3, 57, 1)';
export const mangeta_2= '#780650';
export const mangeta_3= 'rgba(158, 16, 104, 1)';
export const mangeta_6= '#F759AB';
export const mangeta_9= '#FFD6E7';

export const lime_1 = 'rgba(37, 64, 0, 1)';
export const lime_2 = '#3F6600';
export const lime_3 = 'rgba(91, 140, 0, 1)';
export const lime_6 = 'rgba(186, 230, 55, 1)';
export const lime_9 = '#F4FFB8';

export const calendula_gold_1 = 'rgba(97, 52, 0, 1)';
export const calendula_gold_2 = '#874D00';
export const calendula_gold_3 = 'rgba(173, 104, 0, 1)';
export const calendula_gold_6 = 'rgba(255, 197, 61, 1)';

export const sunset_orange_1 = 'rgba(97, 37, 0, 1)';
export const sunset_orange_3 = 'rgba(173, 78, 0, 1)';
export const sunset_orange_6 = 'rgba(255, 169, 64, 1)';


export const dust_red_3 = 'rgba(168, 7, 26, 1)';
export const dust_red_6 = 'rgba(255, 77, 79, 1)';


export const red_1 = '#fff1f0';
export const red_2 = '#ffccc7';
export const red_3 = '#ffa39e';
export const red_4 = '#ff7875';
export const red_5 = '#ff4d4f';
export const red_6 = '#f5222d';
export const red_7 = '#cf1322';
export const red_8 = '#a8071a';
export const red_9 = '#820014';
export const red_10 = '#5c0011';

export const volcano_2 = '#871400';
export const volcano_9 = '#FFD8BF';


export const dark_border = 'rgba(255, 255, 255, 0.06)';
export const switch_bg = '#67747E';
export const border_dark_color = '#565D62';
export const border_dark_color2 = 'rgba(255, 255, 255, 0.08)';
export const success_color = '#73d13d';
export const success_color2= 'rgba(35, 120, 4, 1)';
export const success_bg = '#092B00';
export const active_order_table = '#1F2121FF';


export const blue_6 = '#1890FF';
export const purple_5 = '#722ED1';
export const purple_7 = '#B37FEB';

// export const icon_color = '#656C70';
