import { WarningOutlined } from "@ant-design/icons";
import { dust_red_6 } from "../Cores";
import { Tooltip } from "antd";
import { dateFormat } from "../util/dates";

export default function StoppedEquipmentIcon({ last_sync }) {
  const formatedDate = dateFormat(last_sync, "DD/MM/YYYY HH:mm");

  return (
    <Tooltip
      title={
        <span>
          Equipamento parado.
          <br />
          Atualizado: {formatedDate}
        </span>
      }
    >
      <WarningOutlined
        style={{ color: dust_red_6, marginRight: "5px" }}
      />
    </Tooltip>
  );
}
