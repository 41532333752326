import React, { useEffect, useState } from "react";
import { useActionBar } from "../../context/ActionBarContext";
import { Tabs } from "../../components/Base/Tabs";
import api from "../../services/api";
import { BuildFieldErrorsDict, maskIntNumber } from "../../util/Utils";
import { useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import DividerPage from "../../components/Base/DividerPage";
import DisplayInfo from "../../components/Base/DisplayInfo";
import useSystemBreakpoint from "../../hooks/useSystemBreakpoint";

import useTab from "../../hooks/useTab";
import { Col, Row } from "antd";
import TabEquipmentDetail from "./TabEquipmentDetail";
import TabHourMeter from "./TabHourMeter";
import TabEquipmentClientDetail from "./TabEquipmentClientDetail";
import TabLocation from "./TabLocation";
import Loading from "../../components/Base/Loading";
import { Brand, Chassi, Name } from "../../components/GenericStyles";
import HeaderDetail from "../../components/HeaderDetail";
import TabOpportunityEquipment from "./TabOpportunityEquipment";
import TabTelemetryRecords from "./TabTelemetryRecords";
import TabOilHistory from "./TabOilHistory";
import useActionPermission from "../../hooks/useActionPermission";
import { EditOutlined } from "@ant-design/icons";
import { primary_color } from "../../Cores";
import CenterUptimeOffCanvas from "../../components/equipment/CenterUptimeOffCanvas";
import TabPMP from "./TabPMP";
import { TabOsEquipment } from "./TabOsEquipment";
import TabNearTechnician from "./TabNearTechnician";
import AlertHourmeter from "../../components/equipment/AlertHourmeter";
import { dateFormat } from "../../util/dates";
import BigAccountIcon from "../../components/Base/BigAccountIcon";
import StoppedEquipmentIcon from "../../components/StoppedEquipmentIcon";

const StyledEditOutlined = styled(EditOutlined)`
  color: ${primary_color};
  cursor: pointer;
`;

const EquipementInfo = styled.div`
  margin-top: 96px;
  margin-left: auto;

  @media screen and (max-width: 992px) {
    margin-top: 0px;
    margin-left: 16px;
  }
`;

const HeaderContent = styled.div`
  padding: 64px 16px 16px;

  display: flex;
  flex-direction: column;

  @media screen and (max-width: 992px) {
    padding-top: 16px;
  }
`;

export default function EquipmentDetail() {
  const { changeTitle, setHasBack } = useActionBar();
  const [open, setOpen] = useState();
  const { isDesktop } = useSystemBreakpoint();
  const { hasPermission } = useActionPermission({ permissions: [1, 2, 4, 5] });
  const { setQueryTab, defaultTabId } = useTab({ defaultTab: "1" });
  const [equipment, setEquipment] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const params = useParams();
  const equipmentID = params.id;
  const [previousLocation, setPreviousLocation] = useState(null);
  const location = useLocation();

  useEffect(() => {
    setPreviousLocation(location.state ? location.state.from : null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    changeTitle("Visualizar equipamentos");
    setHasBack();
    setHasBack(previousLocation ? previousLocation : "equipment?load=1");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeTitle, setHasBack]);

  const fetchEquipment = async () => {
    try {
      const response = await api.get(`equipment/${equipmentID}`);
      setEquipment(response.data);
    } catch (error) {
      BuildFieldErrorsDict(error, null, false);
    } finally {
      setIsLoading(false);
    }
  };

  const getDefaultTabkey = () => {
    return defaultTabId;
  };

  const isJohnDeere = () => {
    return equipment?.brand_equipment?.name === "JOHN DEERE";
  };

  useEffect(() => {
    fetchEquipment();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getHourmeterSource = () => {
    if (isJohnDeere()) {
      return (
        <>
          <span style={{ display: "block" }}>
            Dia: {dateFormat(equipment?.summary?.latest_position_date)}
          </span>
          <span style={{ display: "block" }}>Origem: Telemetria</span>
        </>
      );
    } else if (equipment.use_g4_hourmeter) {
      return (
        <>
          <span style={{ display: "block" }}>
            Dia:{" "}
            {dateFormat(equipment?.last_g4_hourmeter?.hourmeter_update_date)}
          </span>
          <span style={{ display: "block" }}>Origem: G4</span>
        </>
      );
    }

    return (
      <>
        <span style={{ display: "block" }}>
          Dia: {dateFormat(equipment?.summary?.last_communication_hourmeter)}
        </span>
        <span style={{ display: "block" }}>Origem: Telemetria</span>
      </>
    );
  };

  const getHourmeterValue = () => {
    if (isJohnDeere()) {
      return equipment?.summary?.current_hour_meter;
    } else if (equipment.use_g4_hourmeter) {
      return equipment?.last_g4_hourmeter.hourmeter;
    }

    return equipment.last_reporting_hourmeter;
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div style={{ marginTop: -16 }}>
            <HeaderDetail src={isDesktop && equipment.type_equipment?.picture}>
              <Row gutter={[64, 0]}>
                <Col xs={24} lg={8}>
                  <HeaderContent>
                    <Chassi>{equipment.pin_17}</Chassi>
                    <Name>
                      {equipment.stopped_data.is_stopped && (
                        <StoppedEquipmentIcon
                          last_sync={equipment.stopped_data.last_sync}
                        />
                      )}
                      {equipment.denomination}
                    </Name>
                    <Brand>{equipment.brand_equipment?.name}</Brand>
                  </HeaderContent>
                </Col>

                <Col xs={24} lg={16}>
                  <EquipementInfo>
                    <Row gutter={[16, 16]}>
                      <Col xs={12} lg={6}>
                        <DisplayInfo label={"CLIENTE"} noWrap>
                          {equipment.client.is_big_account ? (
                            <BigAccountIcon />
                          ) : (
                            ""
                          )}{" "}
                          {equipment.client?.name}
                        </DisplayInfo>
                      </Col>
                      <Col xs={12} lg={4}>
                        <DisplayInfo
                          label={"HORÍMETRO"}
                          noWrap
                          tooltipValue={getHourmeterSource()}
                        >
                          <AlertHourmeter
                            value={equipment?.hourmeter_confiability}
                          />
                          {maskIntNumber(getHourmeterValue())}
                        </DisplayInfo>
                      </Col>
                      <Col xs={12} lg={7}>
                        <DisplayInfo
                          label={"CENTRO(UPTIME CENTER)"}
                          extraIcon={
                            hasPermission && (
                              <StyledEditOutlined
                                onClick={() => setOpen(true)}
                              />
                            )
                          }
                        >
                          {equipment.center?.name}{" "}
                        </DisplayInfo>
                      </Col>
                      <Col xs={12} lg={7}>
                        <DisplayInfo label={"CENTRO(SAP)"} noWrap>
                          {equipment.client?.center?.name}
                        </DisplayInfo>
                      </Col>
                    </Row>
                  </EquipementInfo>
                </Col>
              </Row>
            </HeaderDetail>
          </div>
          <DividerPage />
          <Tabs
            destroyInactiveTabPane={true}
            activeKey={getDefaultTabkey()}
            onChange={setQueryTab}
            items={[
              {
                label: "Detalhes do equipamento",
                key: "1",
                children: (
                  <TabEquipmentDetail
                    equipment={equipment}
                    isJohnDeere={isJohnDeere()}
                  />
                ),
              },
              {
                label: "Detalhes do cliente",
                key: "2",
                children: (
                  <TabEquipmentClientDetail
                    clientId={equipment.client?.id}
                    equipment={equipment}
                    isJohnDeere={isJohnDeere()}
                  />
                ),
              },
              {
                label: "Ordens de Serviços",
                key: "3",
                children: <TabOsEquipment equipment={equipment} />,
                disabled: isJohnDeere(),
              },
              {
                label: "Técnicos Próximos",
                key: "4",
                children: <TabNearTechnician equipment={equipment} />,
                disabled: isJohnDeere(),
              },
              {
                label: "Horímetro",
                key: "5",
                children: (
                  <TabHourMeter
                    equipment_id={equipment.id}
                    summary={equipment.summary}
                    use_g4_hourmeter={equipment.use_g4_hourmeter}
                    g4_hourmeter={equipment.last_g4_hourmeter}
                  />
                ),
              },
              {
                label: "Localização",
                key: "6",
                children: (
                  <TabLocation
                    equipment={equipment}
                    isJohnDeere={isJohnDeere()}
                  />
                ),
              },
              {
                label: "Notificações",
                key: "7",
                children: <TabOpportunityEquipment equipment={equipment} />,
              },
              {
                label: "Registros de telemetria",
                key: "8",
                children: <TabTelemetryRecords equipment={equipment} />,
                disabled: isJohnDeere(),
              },
              {
                label: "Histórico de análises",
                key: "9",
                children: <TabOilHistory equipment={equipment} />,
                disabled: isJohnDeere(),
              },
              {
                label: "PMP",
                key: "10",
                children: <TabPMP equipment={equipment} />,
                disabled: isJohnDeere(),
              },
            ]}
          />
        </>
      )}
      {equipment && hasPermission && (
        <CenterUptimeOffCanvas
          setObject={setEquipment}
          open={open}
          setOpen={setOpen}
          centerId={equipment.center?.id}
          url={`equipment/${equipment.id}/change_center`}
          message={`Você está alterando o centro do uptime center do equipamento ${equipment.pin_17}, do cliente Carregadoras GX. Essa mudança não altera o centro cadastrado no SAP.`}
        />
      )}
    </>
  );
}
