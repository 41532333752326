import React from "react";
import styled from "styled-components";
import PageTitle from "../../components/Base/PageTitle";
import LastDate from "../../components/Base/LastDate";
import { Col, Row } from "antd";
import DisplayInfo from "../../components/Base/DisplayInfo";
import Maps from "../../components/Base/Maps/Maps";
import ShareLocation from "../../components/Base/ShareLocation";
import NoLocation from "../../components/Base/NoLocation";

const TabContainer = styled.div`
  padding-top: 24px;
`;

const StyledLastDate = styled(LastDate)`
  margin-top: 16px;
`;
export default function TabLocation({ equipment, isJohnDeere, ...props }) {
  const latitude =
    equipment.last_equipment_position?.latitude ||
    equipment?.summary?.latest_position_latitude;
  const longitude =
    equipment.last_equipment_position?.longitude ||
    equipment?.summary?.latest_position_longitude;

  function noHasLocation() {
    return !latitude || !longitude;
  }

  const getLastLocationExtraIcon = () => {
    if (isJohnDeere) {
      return (
        <ShareLocation
          lat={equipment?.summary?.latest_position_latitude}
          long={equipment?.summary?.latest_position_longitude}
        />
      );
    }
    if (!equipment.last_equipment_position) return null;

    return (
      <ShareLocation
        lat={equipment.last_equipment_position?.latitude}
        long={equipment.last_equipment_position?.longitude}
      />
    );
  };

  return (
    <TabContainer>
      {noHasLocation() ? (
        <Col xs={24}>
          <NoLocation
            message={
              "O equipamento não reportou sua localização recentemente, então não conseguimos criar um mapa com sua localização."
            }
          />
        </Col>
      ) : (
        <>
          <PageTitle value={"Localização atual"} />
          <Row style={{ marginBottom: 16 }} gutter={[16, 16]}>
            <Col xs={12} lg={8}>
              <DisplayInfo label={"LATITUDE"}>{latitude}</DisplayInfo>
            </Col>
            <Col xs={12} lg={8}>
              <DisplayInfo label={"LONGITUDE"} noWrap>
                {longitude}
              </DisplayInfo>
            </Col>
            <Col xs={12} lg={8}>
              <DisplayInfo
                label={"Última localização"}
                extraIcon={getLastLocationExtraIcon()}
              >
                {equipment.last_equipment_position?.reverse_geocoding ||
                  equipment?.summary?.latest_position_reverse_geocoding}
              </DisplayInfo>
            </Col>
          </Row>
          <Maps
            height={"491px"}
            defaultZoom={15}
            center={{
              lat: Number(latitude),
              lng: Number(longitude),
            }}
            coords={[
              {
                lat: Number(latitude),
                lng: Number(longitude),
                id: 1,
              },
            ]}
          />
          <StyledLastDate
            label={"Última atualização da localização"}
            datetime={
              equipment.last_equipment_position?.updated_at ||
              equipment?.summary?.latest_position_date
            }
          />
        </>
      )}
    </TabContainer>
  );
}
