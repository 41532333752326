export const FINISHED_ID = 7; //ID da tabela Opportunity status
export const NEW_ID = 1; //ID da tabela Opportunity status
export const CANCEL_ID = 9; //ID da tabela Opportunity status
export const TYPE_OS = [
  {
    label: "Cotação de OS Cliente",
    value: "ZCOS",
  },
  {
    label: "Sol de garantia",
    value: "ZCOM",
  },
];

export const TYPE_OS_DICT = {
  ZCOS: "Cotação de OS Cliente",
  ZCOM: "Sol de garantia",
};

export const SYSTEM_CARETRACK_ID = 1;
export const SYSTEM_REMOTE_LOG_ID = 3;

export const INITIAL_OPPORTUNITY_FORM_FILTERS = {
  status_id__in: [1, 2, 3, 4, 5, 6, 8, 10, 11],
  // 'rule__priority__id__in': [1,2,3],
  equipment__warranty_date__isnull: "",
  equipment__pmps__isnull: "",
  big_accounts: "",
  equipment_status: "",
  // 'brand_equipment_id__in': null,
};

export function validateOpportunityType(opportunity, type) {
  return opportunity.type_opportunity === type;
}
