import FingerScroll from "../../../components/Base/FingerScroll";
import HourMeterCard from "../../../components/Base/HourMeterCard";
import PageTitle from "../../../components/Base/PageTitle";
import InfoCard from "../../../components/Base/display/InfoCard";
import PageText from "../../../components/Base/display/PageText";
import { maskIntNumber } from "../../../util/Utils";

export default function AlertOpportunity({ opportunity, summary }) {
  return (
    <>
      <PageTitle value="Dados do Alerta" />
      <FingerScroll>
        <InfoCard
          width={212}
          data={opportunity.machine_alert.alert_three_letter_code}
          title={"Código de Três Letras"}
        />
        <InfoCard
          width={212}
          data={opportunity.machine_alert.alert_color}
          title={"Cor do Alerta"}
        />
        <InfoCard
          width={212}
          data={opportunity.machine_alert.alert_severity}
          title={"Severidade do Alerta"}
        />
      </FingerScroll>
      <PageTitle value={"Descrição do Alerta"} style={{ marginTop: 15 }} />
      <PageText value={opportunity.machine_alert.alert_description} />
      <PageTitle
        value={"Estatísticas do horímetro"}
        style={{ marginTop: 15 }}
      />
      <FingerScroll>
        <HourMeterCard
          date={
            summary?.current_hour_meter
              ? `${maskIntNumber(summary?.current_hour_meter)}`
              : "-"
          }
          title={"Horímetro atual"}
          label={"Total"}
        />
        <HourMeterCard
          date={
            summary?.average_seven_days
              ? `${summary?.average_seven_days}h`
              : "-"
          }
          title={"Últimos 7 dias"}
          label={"Média diária"}
        />
        <HourMeterCard
          date={
            summary?.average_fifteen_days
              ? `${summary?.average_fifteen_days}h`
              : "-"
          }
          title={"Últimos 15 dias"}
          label={"Média diária"}
        />
        <HourMeterCard
          date={summary?.average_overall ? `${summary?.average_overall}h` : "-"}
          title={`Geral (${
            summary?.tracked_since ? summary?.tracked_since : "-"
          })`}
          label={"Média diária"}
        />
      </FingerScroll>
    </>
  );
}
