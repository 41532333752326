import React, { useEffect, useState } from "react";
import { Button, Form } from "antd";

import styled from "styled-components";

import { text_color } from "../Cores";
import { BuildFieldErrorsDict, buildFormDataToFilter } from "../util/Utils";
import { set_filter_params } from "../util/TableStorageService";
import api from "../services/api";
import OffCanvas from "./Base/OffCanvas";
import CheckboxOffCanvas from "./FiltersOffCanvas/CheckboxOffCanvas";
import { useStaticDataContext } from "../context/StaticDataContext";
import SelectSearchOffCanvas from "./FiltersOffCanvas/SelectSearchOffCanvas";
import Collapse from "./Collapse";
import RadioButton from "./Base/Forms/RadioButton";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  //flex
`;
const Title = styled.h4`
  color: ${text_color};
  margin: 0;
`;

const BRAND_FORM_NAME = "brand_equipment_id__in";
const CLIENT_NAME = "equipment__client_id__in";

export default function FilterEquipmentDashboardNotificationOffCanvas({
  open,
  setOpen,
  initialFormFilter,
  setFormFilter,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [brands, setBrands] = useState([]);
  const [types, setTypes] = useState([]);
  const { centers } = useStaticDataContext();
  const [form] = Form.useForm();

  function onReset() {
    form.resetFields();
    // form.setFieldsValue(INITIAL_OPPORTUNITY_FORM_FILTERS)
  }

  useEffect(() => {
    async function fetchData(url, setData) {
      try {
        const response = await api.get(url);
        setData(response.data.results);
      } catch (error) {
        BuildFieldErrorsDict(error, null, false);
      }
    }

    async function fetchDataPost(url, setData) {
      try {
        const response = await api.post(url);
        setData(response.data.results);
      } catch (error) {
        BuildFieldErrorsDict(error, null, false);
      }
    }

    fetchData("brand_equipment?page_size=1000", setBrands);
    fetchDataPost("type_equipment/list_post?page_size=1000&page=1", setTypes);
  }, []);

  async function onSubmit() {
    setIsLoading(true);
    let values;
    try {
      values = await form.validateFields();
      if (
        values[CLIENT_NAME] === undefined ||
        values[CLIENT_NAME].length === 0
      ) {
        delete values[CLIENT_NAME];
      }
      let formData = buildFormDataToFilter(values);
      set_filter_params("formFilter", values);
      setFormFilter(formData);
      setOpen(false);
      // Notification('success', 'Filtro atualizado com sucesso.')
    } catch (error) {
      console.log(error);
      // return;
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <OffCanvas
      title={<Title level={4}>Filtrar equipamentos</Title>}
      // onClose={onClose}
      onClose={() => {
        setOpen(false);
      }}
      open={open}
      width={"394px"}
      footer={
        <>
          <Button onClick={onReset}>Redefinir filtros</Button>
          <Button type="primary" onClick={onSubmit} loading={isLoading}>
            Filtrar
          </Button>
        </>
      }
    >
      <Form
        form={form}
        onValuesChange={() => {
          // onFormChange()
        }}
      >
        <CheckboxOffCanvas
          collapseTitle={"marca"}
          form={form}
          items={brands}
          initialValue={brands.map((item) => item.id)}
          hasSearch
          defaultActiveKey={0}
          itemName={BRAND_FORM_NAME}
          placeholder={"Pesquise pela marca"}
        />

        <CheckboxOffCanvas
          collapseTitle={"Tipo"}
          form={form}
          items={types}
          defaultActiveKey={0}
          initialValue={types.map((item) => item.id)}
          hasSearch
          itemName={"equipment__type_equipment_id__in"}
          placeholder={"Pesquise pelo tipo"}
        />

        <CheckboxOffCanvas
          collapseTitle={"Centro"}
          form={form}
          items={centers}
          defaultActiveKey={0}
          initialValue={centers.map((item) => item.id)}
          hasSearch
          itemName={"center_id__in"}
          placeholder={"Pesquise pelo centro"}
        />
        <SelectSearchOffCanvas
          collapseTitle={"Clientes"}
          form={form}
          defaultActiveKey={0}
          itemName={CLIENT_NAME}
          placeholder={"Pesquise pelo cliente"}
          url={"client_select"}
        />

        <Collapse title={"GRANDES CONTAS"} defaultActiveKey={["0"]}>
          <Container>
            <Form.Item noStyle name="big_accounts" initialValue={""}>
              <RadioButton
                options={[
                  { value: "", label: "Todos" },
                  { value: true, label: "Sim" },
                  { value: false, label: "Não" },
                ]}
              ></RadioButton>
            </Form.Item>
          </Container>
        </Collapse>

        <Collapse title={"STATUS DO EQUIPAMENTO"} defaultActiveKey={["0"]}>
          <Container>
            <Form.Item noStyle name="equipment_status" initialValue={""}>
              <RadioButton
                options={[
                  { value: "", label: "Todos" },
                  { value: true, label: "Parado" },
                  { value: false, label: "Em funcionamento" },
                ]}
              ></RadioButton>
            </Form.Item>
          </Container>
        </Collapse>
      </Form>
    </OffCanvas>
  );
}
