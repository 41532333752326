import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DisplayInfo from "../../components/Base/DisplayInfo";
import { Col, Row } from "antd";
import ShareLocation from "../../components/Base/ShareLocation";
import AlertTelemetryStatus from "../../components/equipment/AlertTelemetryStatus";
import { dateFormat } from "../../util/dates";
import WarrantyDateAlert from "../../components/Opportunity/WarrantyDateAlert";
import AlertPMP from "../../components/Opportunity/AlertPMP";
import RenewPMP from "../../components/RenewPMP";
import PMPObservation from "../../components/PMPObservation";
import api from "../../services/api";
import AlertOS from "../../components/Opportunity/AlertOS";
import AlertHourmeter from "../../components/equipment/AlertHourmeter";
import SystemConfiabilityAlert from "../../components/Opportunity/SystemConfiabilityAlert";

const TabContainer = styled.div`
  padding-top: 24px;
`;

export default function TabEquipmentDetail({ equipment, isJohnDeere }) {
  const [osList, setOsList] = useState([]);

  useEffect(() => {
    async function fetchOsList() {
      try {
        if (!equipment.id || isJohnDeere) return;

        const response = await api.get(`equipment/${equipment.id}/get_os_list`);
        const activeOs = response.data.filter(
          (os) =>
            !["concluída", "cancelada", "finalizda"].includes(
              os.status.toLowerCase()
            )
        );
        setOsList(activeOs);
      } catch (error) {
        console.error(error);
      }
    }

    fetchOsList();
  }, [equipment.id, isJohnDeere]);

  const getLastLocationExtraIcon = () => {
    if (isJohnDeere) {
      return (
        <ShareLocation
          lat={equipment?.summary?.latest_position_latitude}
          long={equipment?.summary?.latest_position_longitude}
        />
      );
    }
    if (!equipment.last_equipment_position) return null;

    return (
      <ShareLocation
        lat={equipment.last_equipment_position?.latitude}
        long={equipment.last_equipment_position?.longitude}
      />
    );
  };

  const getLastUpdateDate = () => {
    if (isJohnDeere) {
      return dateFormat(equipment?.summary?.latest_position_date, "DD/MM/YYYY");
    }
    if (!equipment.last_communication) return null;

    return dateFormat(equipment.last_communication, "DD/MM/YYYY, [às] HH[h]mm");
  };

  const getIdLabel = () => {
    if (isJohnDeere) {
      return "id";
    }

    return "id (sap)";
  };

  const getSerialNumberLabel = () => {
    if (isJohnDeere) {
      return "Número de Série";
    }

    return "Chassis / Pin17";
  };

  if (!equipment) return;

  return (
    <TabContainer>
      <Row gutter={[16, 16]}>
        <Col xs={12} md={8}>
          <DisplayInfo label={getIdLabel()}>{equipment.id}</DisplayInfo>
        </Col>
        <Col xs={12} md={8}>
          <DisplayInfo
            label={"Última localização"}
            extraIcon={getLastLocationExtraIcon()}
          >
            {equipment.last_equipment_position?.reverse_geocoding}
          </DisplayInfo>
        </Col>
        <Col xs={12} md={8}>
          <DisplayInfo label={"sistema de telemetria"}>
            {equipment.telemetry_system?.name}
          </DisplayInfo>
        </Col>
        <Col xs={12} md={8}>
          <DisplayInfo label={getSerialNumberLabel()}>
            {equipment.pin_17 || equipment.serial_number}
          </DisplayInfo>
        </Col>
        <Col xs={12} md={8}>
          <DisplayInfo
            showTooltip={false}
            label={"CONFIABILIDADE DA TELEMETRIA"}
          >
            <SystemConfiabilityAlert
              value={equipment?.system_reliability || ""}
            />
          </DisplayInfo>
        </Col>
        <Col xs={12} md={8}>
          <DisplayInfo
            showTooltip={false}
            label={"CONFIABILIDADE DO HORÍMETRO"}
          >
            <AlertHourmeter value={equipment?.hourmeter_confiability} hasText />
          </DisplayInfo>
        </Col>
        <Col xs={12} md={8}>
          <DisplayInfo
            label={"Última atualização (Comunicação com Telemetria)"}
          >
            {getLastUpdateDate()}
          </DisplayInfo>
        </Col>{" "}
        <Col xs={12} md={8} hidden={isJohnDeere}>
          <DisplayInfo showTooltip={false} label={"PMP"}>
            <AlertPMP value={equipment.PMP} />
            {equipment.renew_PMP && (
              <RenewPMP
                text={"PMP próximo ao vencimento. Recomendado renovar."}
              />
            )}
            {equipment.last_pmp && (
              <PMPObservation
                text={`Último contrato: ${equipment.last_pmp.contract_number} (${equipment.last_pmp.expired_at})`}
              />
            )}
            {!equipment.last_pmp && !equipment.renew_PMP && (
              <PMPObservation text={"Sem informações adicionais"} />
            )}
          </DisplayInfo>
        </Col>
        <Col xs={12} md={8} hidden={isJohnDeere}>
          <DisplayInfo label={"OS Ativa"}>
            <AlertOS value={osList.length && "Sim"} />
          </DisplayInfo>
        </Col>
        <Col xs={12} md={8} hidden={isJohnDeere}>
          <DisplayInfo label={"Garantia (Final)"}>
            <WarrantyDateAlert
              date={equipment.warranty_date}
              brandEquipmentName={equipment.brand_equipment?.name}
              hourmeter={equipment.last_reporting_hourmeter}
            />
          </DisplayInfo>
        </Col>
      </Row>
    </TabContainer>
  );
}
