import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { CloseOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal } from "antd";
import RadioButton from "../../../components/Base/Forms/RadioButton";
import DragAndDropUpload from "../../../components/Base/Forms/DragAndDropUpload";
import Select from "../../../components/Base/Select";
import { FINISHED_ID, TYPE_OS } from "../../../util/OpportunityUtils";
import TextArea from "antd/es/input/TextArea";
import api from "../../../services/api";
import { BuildFieldErrorsDict } from "../../../util/Utils";
import { Notification } from "../../../notification";
import { cities } from "../../../util/cities";

const Container = styled.div`
  height: 374px;
  overflow: hidden;
`;
const ContentContainer = styled.div`
  min-height: 204px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledCloseOutlined = styled(CloseOutlined)`
  position: absolute;
  top: 13px;
  right: 16px;
  z-index: 3;
  cursor: pointer;
`;

export default function CreateCotacaoOsModal({
  setOpportunity,
  isModalOpen,
  setClose,
  opportunity,
  client,
  textoDescricao
}) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const onClose = () => {
    setClose(false);
  };

  const onSubmit = async () => {
    let values;
    try {
      values = await form.validateFields();
    } catch (error) {
      Notification("error", "Erros encontrados");
      setIsLoading(false);
      return;
    }

    try {
      setIsLoading(true);
      const response = await api.post(
        `opportunity/${opportunity.id}/create_os`,
        values
      );
      setOpportunity((opportunity) => ({
        ...opportunity,
        budget: response.data,
      }));
    } catch (error) {
      BuildFieldErrorsDict(error, null, false);
    } finally {
      setClose(false);
      setIsLoading(false);
    }
  };

  return (
    <Modal
      open={isModalOpen}
      width={"330px"}
      bodyStyle={{ padding: 0 }}
      title={"Criar nova cotação"}
      footer={[
        <Button onClick={() => setClose(false)}>Cancelar</Button>,
        <Button loading={isLoading} type={"primary"} onClick={onSubmit}>
          Criar OS
        </Button>,
      ]}
      closable={false}
      okText={"Criar OS"}
    >
      <StyledCloseOutlined onClick={onClose} />
      <ContentContainer>
        <Form layout={"vertical"} form={form}>
          <Form.Item
            name={"type"}
            initialValue={"ZCOS"}
            label={"Tipo de OS"} // falta lista
            rules={[
              {
                required: true,
                message: "Tipo de OS é obrigatório",
              },
            ]}
          >
            <Select options={TYPE_OS} />
          </Form.Item>
          <Form.Item
            name={"description"}
            initialValue={textoDescricao ? textoDescricao : opportunity.type_opportunity}
            rules={[
              {
                required: true,
                message: "Descrição é obrigatório",
              },
            ]}
            label={"Descrição"}
          >
            <TextArea />
          </Form.Item>
          <Form.Item
            name={"city"}
            initialValue={client.city}
            rules={[
              {
                required: true,
                message: "Cidade é obrigatória",
              },
            ]}
            label={"Cidade"}
          >
            <Select
              showSearch
              allowClear
              options={cities.map((item) => ({ label: item, value: item }))}
            />
          </Form.Item>
        </Form>
      </ContentContainer>
    </Modal>
  );
}
