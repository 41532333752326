import React, { useEffect } from "react";
import api from "../../services/api";
import { BuildFieldErrorsDict, maskIntNumber } from "../../util/Utils";
import useTable from "../../hooks/useTable";
import UpTable from "../../components/Base/UPTable";
import { EyeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { actionbar_bg } from "../../Cores";
import WarrantyDateAlert from "../../components/Opportunity/WarrantyDateAlert";
import { dateFormat } from "../../util/dates";
import AlertTelemetryStatus from "../../components/equipment/AlertTelemetryStatus";
import { InfoPMP } from "../../components/InfoPMP";
import AlertHourmeter from "../../components/equipment/AlertHourmeter";
import { Tooltip } from "antd";
import BigAccountIcon from "../../components/Base/BigAccountIcon";
import StoppedEquipmentIcon from "../../components/StoppedEquipmentIcon";

export const StyledEyeOutlined = styled(EyeOutlined)`
  padding: 8px;
  border-radius: 8px;

  :hover {
    background: ${actionbar_bg};
  }
`;

const ListEquipmentColumnsTable = [
  "name",
  "chassis_no",
  "pin_17",
  "brand_equipment_name",
  "PMP",
  "warranty_date",
  "last_reporting_hourmeter",
  "telemetry_status",
  "last_communication",
  "client_name",
  "center",
];
const ListClientEquipmentsColumnsTable = [
  "chassis_no",
  "pin_17",
  "type_equipment_name",
  "brand_equipment_name",
  "PMP",
  "warranty_date",
  "telemetry_status",
  "last_communication",
  "last_reporting_hourmeter",
  "center",
];
export default function EquipmentTable({
  setSearch,
  setFilterCount,
  formFilter,
  search,
  clientId,
}) {
  let navigate = useNavigate();

  const getHourmeterSource = (record) => {
    if (record.use_g4_hourmeter) {
      return (
        <>
          <span style={{ display: "block" }}>
            Dia: {dateFormat(record?.last_g4_hourmeter?.hourmeter_update_date)}
          </span>
          <span style={{ display: "block" }}>Origem: G4</span>
        </>
      );
    } else if (record.brand_equipment_name === "JOHN DEERE") {
      return (
        <>
          <span style={{ display: "block" }}>
            Dia: {dateFormat(record?.last_summary?.latest_position_date)}
          </span>
          <span style={{ display: "block" }}>Origem: Telemetria</span>
        </>
      );
    }

    return (
      <>
        <span style={{ display: "block" }}>
          Dia: {dateFormat(record?.last_hourmeter_date)}
        </span>
        <span style={{ display: "block" }}>Origem: Telemetria</span>
      </>
    );
  };

  const columns = [
    {
      title: "Denominação",
      dataIndex: "name",
      sorter: "name",
      key: "name",
      width: "391px",
      ellipsis: true,
      render: (value, record) => {
        const { is_stopped, last_sync } = record.stopped_data;
        return (
          <>
            {is_stopped ? <StoppedEquipmentIcon last_sync={last_sync} /> : ""}
            {value}
          </>
        );
      },
    },
    {
      title: "Chassis",
      dataIndex: "chassis_no",
      key: "chassis_no",
      sorter: "chassis_no",
      width: "150px",
      ellipsis: true,
    },
    {
      title: "Nº série",
      dataIndex: "pin_17",
      key: "pin_17",
      sorter: "pin_17",
      width: "210px",
      ellipsis: true,
      render: (value, record) => {
        if (record.serial_number) {
          return <span>{record.serial_number}</span>;
        }
        return <span>{value}</span>;
      },
    },

    {
      title: "Marca",
      key: "brand_equipment_name",
      dataIndex: "brand_equipment_name",
      sorter: "brand_equipment__name",
      width: "87px",
      ellipsis: true,
    },
    {
      title: "Tipo",
      key: "type_equipment_name",
      dataIndex: "type_equipment_name",
      sorter: "type_equipment_name",
      width: "150px",
      ellipsis: true,
    },
    {
      title: "Horímetro",
      key: "last_reporting_hourmeter",
      dataIndex: "last_reporting_hourmeter",
      width: "111px",
      sorter: "hourmeter",
      ellipsis: true,
      render: (value, record) => {
        if (record.use_g4_hourmeter) {
          return (
            <Tooltip title={getHourmeterSource(record)}>
              <AlertHourmeter value={record?.hourmeter_confiability} />
              {maskIntNumber(record.last_g4_hourmeter.hourmeter)}
            </Tooltip>
          );
        } else if (record.brand_equipment_name === "JOHN DEERE") {
          return (
            <Tooltip title={getHourmeterSource(record)}>
              <AlertHourmeter value={record?.hourmeter_confiability} />
              {maskIntNumber(record?.last_summary?.current_hour_meter)}
            </Tooltip>
          );
        }
        return (
          <Tooltip title={getHourmeterSource(record)}>
            <AlertHourmeter value={record?.hourmeter_confiability} />
            {maskIntNumber(value)}
          </Tooltip>
        );
      },
    },

    {
      title: "Cliente",
      key: "client_name",
      dataIndex: "client_name",
      sorter: "client__name",
      width: "391px",
      ellipsis: true,
      render: (value, record) => {
        return (
          <>
            {record.client_is_big_account ? <BigAccountIcon /> : ""}
            {value}
          </>
        );
      },
    },
    {
      title: "Centro",
      key: "center",
      dataIndex: "center",
      sorter: "center__name",
      width: "391px",
      ellipsis: true,
      render: (value, record) => <>{value?.name}</>,
    },
    {
      title: "PMP",
      key: "PMP",
      dataIndex: "PMP",
      sorter: "pmps__end_dt",
      width: "56px",
      render: (value, record) => (
        <InfoPMP renew_PMP={record.renew_PMP} PMP={value} />
      ),
    },
    {
      title: "Garantia (final)",
      dataIndex: "warranty_date",
      sorter: "warranty_date",
      key: "warranty_date",
      width: "126px",
      render: (value, record) => (
        <WarrantyDateAlert
          date={value}
          brandEquipmentName={record.brand_equipment_name}
          hourmeter={record.last_reporting_hourmeter}
        />
      ),
    },
    {
      title: "Status da Telemetria",
      key: "telemetry_status",
      dataIndex: "telemetry_status",
      sorter: "telemetry_status",
      width: "200px",
      render: (value, record) => <AlertTelemetryStatus value={value} />,
    },
    {
      title: "Última atualização",
      key: "last_communication",
      dataIndex: "last_communication",
      sorter: "last_communication",
      width: "200px",
      render: (value, record) => value && dateFormat(value, "DD/MM/YYYY"),
    },
  ];
  const fetchEquipments = async ({
    setIsLoading,
    pagination,
    order,
    setTableParams,
    setTableData,
  }) => {
    try {
      setIsLoading(true);
      let data = formFilter;

      if (clientId) {
        try {
          data.append("client__id", clientId);
        } catch (error) {
          BuildFieldErrorsDict(error, null, false);
        }
      }

      const response = await api.post(
        `equipment/list_post?page_size=${pagination.pageSize}&page=${
          pagination.current
        }&search=${search}&ordering=${order ? order : ""}`,
        data
      );

      setTableData(response.data.results);

      setTableParams((tableParams) => {
        return {
          ...tableParams,
          pagination: {
            ...pagination,
            total: response.data.count,
          },
        };
      });
    } catch (error) {
      BuildFieldErrorsDict(error, null, false);
    } finally {
      setIsLoading(false);
    }
  };

  const {
    tableData,
    isLoading,
    tableParams,
    resetTable,
    handleTableChange,
    handleRequestTable,
  } = useTable({
    fetchData: fetchEquipments,
    search,
    useHandleRequestTable: false,
  });

  useEffect(() => {
    if (formFilter) {
      const size = new Set(formFilter.keys()).size;
      setFilterCount(size < 1 ? 1 : size);

      handleRequestTable();
    }
    // eslint-disable-next-line
  }, [search, formFilter]);

  const handleEquipmentDetail = (record) => {
    navigate(`/equipment/${record.id}/detail?tab=1`);
  };

  const getColumns = () => {
    let columnsDict = ListEquipmentColumnsTable;

    if (clientId) {
      columnsDict = ListClientEquipmentsColumnsTable;
    }

    return columns.filter((column) => {
      return columnsDict.includes(column.dataIndex);
    });
  };

  return (
    <UpTable
      onChange={handleTableChange}
      onRowClick={handleEquipmentDetail}
      scroll={{
        y: "350px",
      }}
      loading={isLoading}
      showQuickJumper
      tableParams={tableParams}
      columns={getColumns()}
      dataSource={tableData}
    />
  );
}
