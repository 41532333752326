import React, { useEffect } from "react";
import UpTable from "../../Base/UPTable";
import useTable from "../../../hooks/useTable";
import api from "../../../services/api";
import { BuildFieldErrorsDict, maskIntNumber } from "../../../util/Utils";
import { useNavigate } from "react-router-dom";
import BadgeOportunityStatus from "../../Base/BadgeOportunityStatus";
import AvatarProfile from "../../AvatarProfile";
import WarrantyDateAlert from "../WarrantyDateAlert";
import AlertPMP from "../AlertPMP";
import DateAlert from "../../DateAlert";
import { set_filter_params } from "../../../util/TableStorageService";
import AlertOS from "../AlertOS";
import { Tooltip } from "antd";
import AlertPriority from "../AlertPriority";
import BadgeCondition from "../../Base/display/BadgeCondition";
import {
  SYSTEM_CARETRACK_ID,
  SYSTEM_REMOTE_LOG_ID,
} from "../../../util/OpportunityUtils";
import { dateFormat } from "../../../util/dates";
import { InfoPMP } from "../../InfoPMP";
import AlertHourmeter from "../../equipment/AlertHourmeter";
import BigAccountIcon from "../../Base/BigAccountIcon";
import StoppedEquipmentIcon from "../../StoppedEquipmentIcon";

const ListOpportunityColumnsType = {
  //Ao duplicar deve adicionar no columns
  all: [
    "id",
    "type_opportunity",
    "client_name",
    "pin_17",
    "brand_name",
    "center_name",
    "status",
    "responsible",
    "PMP",
    "warranty_date",
    "active_opportunity_hourmeter",
  ],
  "Análise de óleo": [
    "id",
    "client_name",
    "pin_17",
    "brand_name",
    "current_hour_meter",
    "center_name",
    "status",
    "responsible",
    "PMP",
    "condition_name",
    "warranty_date",
  ],
  "Revisão programada": [
    "id",
    "client_name",
    "pin_17",
    "brand_name",
    "next_review_hour_meter",
    "current_hour_meter",
    "center_name",
    "status",
    "next_review_date",
    "responsible",
    "PMP",
    "warranty_date",
  ],
  "Código de erro": [
    "id",
    "client_name",
    "pin_17",
    "brand_name",
    "current_hour_meter",
    "center_name",
    "status",
    "telemetric_data",
    "responsible",
    "priority_name",
    "PMP",
    "warranty_date",
  ],
  "Renovação de PMP": [
    "id",
    "client_name",
    "pin_17",
    "brand_name",
    "current_hour_meter",
    "center_name",
    "status",
    "responsible",
    "PMP",
    "expiration_pmp_days",
    "expiration_pmp_date",
  ],
  "Novos contratos de PMP": [
    "id",
    "client_name",
    "pin_17",
    "brand_name",
    "current_hour_meter",
    "center_name",
    "status",
    "responsible",
    "PMP",
    "equipment__already_had_PMP",
  ],
  Alerta: [
    "id",
    "type_opportunity",
    "client_name",
    "pin_17",
    "brand_name",
    "center_name",
    "status",
    "responsible",
    "PMP",
    "warranty_date",
    "active_opportunity_hourmeter",
  ],
};

const EquipmentOpportunityColumnsType = {
  //Ao duplicar deve adicionar no columns
  all: [
    "id",
    "type_opportunity",
    "status",
    "brand_name",
    "responsible",
    "warranty_date",
  ],
  "Análise de óleo": [
    "id",
    "brand_name",
    "status",
    "responsible",
    "condition_name",
    "warranty_date",
  ],
  "Revisão programada": [
    "id",
    "brand_name",
    "next_review_hour_meter",
    "status",
    "next_review_date",
    "responsible",
    "warranty_date",
  ],
  "Código de erro": [
    "id",
    "status",
    "brand_name",
    "telemetric_data",
    "priority_name",
    "responsible",
    "warranty_date",
  ],
  "Renovação de PMP": [
    "id",
    "client_name",
    "pin_17",
    "brand_name",
    "current_hour_meter",
    "center_name",
    "status",
    "responsible",
    "PMP",
    "expiration_pmp_days",
    "expiration_pmp_date",
  ],
  "Novos contratos de PMP": [
    "id",
    "client_name",
    "pin_17",
    "brand_name",
    "current_hour_meter",
    "center_name",
    "status",
    "responsible",
    "PMP",
    "equipment__already_had_PMP",
  ],
};

const ClientOpportunityColumnsType = {
  //Ao duplicar deve adicionar no columns
  all: [
    "id",
    "type_opportunity",
    "pin_17",
    "brand_name",
    "current_hour_meter",
    "center_name",
    "status",
    "responsible",
    "PMP",
    "warranty_date",
  ],
  "Análise de óleo": [
    "id",
    "pin_17",
    "brand_name",
    "current_hour_meter",
    "center_name",
    "status",
    "responsible",
    "PMP",
    "condition_name",
    "warranty_date",
  ],
  "Revisão programada": [
    "id",
    "pin_17",
    "brand_name",
    "next_review_hour_meter",
    "current_hour_meter",
    "center_name",
    "status",
    "next_review_date",
    "responsible",
    "PMP",
    "warranty_date",
  ],
  "Código de erro": [
    "id",
    "pin_17",
    "brand_name",
    "current_hour_meter",
    "center_name",
    "status",
    "telemetric_data",
    "responsible",
    "priority_name",
    "PMP",
    "warranty_date",
  ],
  "Renovação de PMP": [
    "id",
    "client_name",
    "pin_17",
    "brand_name",
    "current_hour_meter",
    "center_name",
    "status",
    "responsible",
    "PMP",
    "expiration_pmp_days",
    "expiration_pmp_date",
  ],
  "Novos contratos de PMP": [
    "id",
    "client_name",
    "pin_17",
    "brand_name",
    "current_hour_meter",
    "center_name",
    "status",
    "responsible",
    "PMP",
    "equipment__already_had_PMP",
  ],
};

export default function OpportunityTable({
  search,
  formFilter,
  typeTable,
  equipmentId = null,
  clientId = null,
  setFilterCount,
}) {
  let navigate = useNavigate();

  const fetchOpportunity = async ({
    setIsLoading,
    pagination,
    order,
    setTableParams,
    setTableData,
  }) => {
    try {
      setIsLoading(true);
      const queryTypeTable =
        typeTable !== "all" ? `rule__rule_type__name=${typeTable}&` : "";
      if (equipmentId) {
        try {
          formFilter.append("equipment__id", equipmentId);
        } catch (error) {
          BuildFieldErrorsDict(error, null, false);
        }
      }
      if (clientId) {
        try {
          formFilter.append("equipment__client__id", clientId);
        } catch (error) {
          BuildFieldErrorsDict(error, null, false);
        }
      }
      if (queryTypeTable) {
        try {
          formFilter.append("rule__rule_type__name", typeTable);
        } catch (error) {
          BuildFieldErrorsDict(error, null, false);
        }
      }

      const response = await api.post(
        `opportunity/list_post?${queryTypeTable}page_size=${
          pagination.pageSize
        }&page=${pagination.current}&search=${search}&ordering=${
          order ? order : ""
        }`,
        formFilter
      );
      setTableData(response.data.results);
      setTableParams((tableParams) => {
        return {
          ...tableParams,
          pagination: {
            ...pagination,
            total: response.data.count,
          },
        };
      });
      set_filter_params("typeTable", typeTable);
    } catch (error) {
      BuildFieldErrorsDict(error, null, false);
    } finally {
      setIsLoading(false);
    }
  };

  const {
    tableData,
    isLoading,
    tableParams,
    resetTable,
    handleTableChange,
    handleRequestTable,
  } = useTable({
    fetchData: fetchOpportunity,
    search,
    useHandleRequestTable: false,
  });

  const columns = [
    {
      title: "Cód.",
      dataIndex: "id",
      sorter: "id",
      key: "id",
      width: "80px",
      render: (value, record) => {
        const { is_stopped, last_sync } = record.equipment_stopped_data;

        return (
          <>
            {is_stopped && <StoppedEquipmentIcon last_sync={last_sync} />}
            {value}
          </>
        );
      },
    },
    {
      title: "Tipo da notificação",
      dataIndex: "type_opportunity",
      sorter: "type_opportunity",
      key: "type_opportunity",
      width: "180px",
    },
    {
      title: "Cliente",
      dataIndex: "client_name",
      sorter: "equipment__client__name",
      width: "138px",
      ellipsis: true,
      render: (value, record) => {
        return (
          <>
            {record.client_is_big_account ? <BigAccountIcon /> : ""}
            {value}
          </>
        );
      },
    },
    {
      title: "Marca",
      dataIndex: "brand_name",
      sorter: "brand_equipment__name",
      width: "138px",
      ellipsis: true,
    },
    {
      title: "N° Série / PIN17",
      dataIndex: "pin_17",
      sorter: "equipment__pin_17",
      key: "pin_17",
      width: "190px",
      ellipsis: true,
    },
    {
      title: "Hr. prox. revisão",
      dataIndex: "next_review_hour_meter",
      sorter: "next_review_hour_meter",
      key: "next_review_hour_meter",
      width: "149px",
      ellipsis: true,
      render: (value, record) => maskIntNumber(value),
    },
    {
      title: "Horimetro na criação",
      dataIndex: "active_opportunity_hourmeter",
      sorter: "active_opportunity_hourmeter",
      key: "active_opportunity_hourmeter",
      width: "100px",
      render: (value, record) => (
        <span>
          <AlertHourmeter value={record?.hourmeter_confiability_on_creation} />
          {maskIntNumber(value)}
        </span>
      ),
    },
    {
      title: "Horimetro atual",
      dataIndex: "current_hour_meter",
      key: "current_hour_meter",
      sorter: "equipment__hourmeter",
      width: "100px",
      render: (value, record) => (
        <span>
          <AlertHourmeter value={record?.hourmeter_confiability} />
          {maskIntNumber(value)}
        </span>
      ),
    },
    {
      title: "Dt. revisão (est.)",
      dataIndex: "next_review_date",
      sorter: "next_review_date",
      key: "next_review_date",
      width: "151px",
      render: (value, record) => <DateAlert date={value} />,
    },
    {
      title: "Código de erro",
      dataIndex: "telemetric_data",
      key: "telemetric_data",
      width: "150px",
      ellipsis: true,
      render: (value, record) => {
        function getDescription() {
          let description = "";
          if (record.telemetry_system?.id === SYSTEM_CARETRACK_ID) {
            description = record.telemetric_data?.description;
          } else if (record.telemetry_system?.id === SYSTEM_REMOTE_LOG_ID) {
            description = record.content_object?.caution_description;
          }
          return description;
        }

        return (
          <>
            {record.telemetry_system && (
              <Tooltip title={getDescription()}>
                {record.telemetry_system.id === SYSTEM_REMOTE_LOG_ID &&
                  `${record.content_object?.identifier}`}
                {record.telemetry_system.id === SYSTEM_CARETRACK_ID &&
                  `${record.telemetric_data?.mid}/${record.telemetric_data?.pid}/${record.telemetric_data?.fmi}`}
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      title: "Severidade",
      dataIndex: "priority_name",
      key: "priority_name",
      width: "90px",
      render: (value, record) => <AlertPriority value={value} />,
    },
    {
      title: "Condição",
      dataIndex: "condition_name",
      key: "condition_name",
      width: "100px",
      render: (value) =>
        value && <BadgeCondition type={value}>{value}</BadgeCondition>,
    },
    {
      title: "Centro",
      dataIndex: "center_name",
      sorter: "center__name",
      key: "center_name",
      width: "150px",
      ellipsis: true,
    },
    {
      title: "Garantia (final)",
      dataIndex: "warranty_date",
      sorter: "equipment__warranty_date",
      key: "warranty_date",
      width: "126px",
      render: (value, record) => (
        <WarrantyDateAlert
          date={value}
          brandEquipmentName={record.brand_name}
          hourmeter={record.current_hour_meter}
        />
      ),
    },
    {
      title: "PMP",
      dataIndex: "PMP",
      key: "PMP",
      sorter: "equipment__pmps__end_dt",
      width: "75px",
      render: (value, record) => (
        <InfoPMP renew_PMP={record.renew_PMP} PMP={value} />
      ),
    },
    {
      title: "Já teve PMP",
      dataIndex: "equipment__already_had_PMP",
      sorter: "equipment__already_had_PMP",
      key: "equipment__already_had_PMP",
      width: "120px",
      render: (value, record) => <AlertPMP value={value} />,
    },
    {
      title: "Dias p/ venc PMP",
      dataIndex: "expiration_pmp_days",
      sorter: "equipment__pmps__end_dt",
      key: "equipment__pmps__end_dt",
      width: "151px",
    },
    {
      title: "Data venc PMP",
      dataIndex: "expiration_pmp_date",
      sorter: "equipment__pmps__end_dt",
      key: "expiration_pmp_date",
      width: "151px",
      render: (value, record) => dateFormat(value),
    },
    {
      title: "Endereço",
      dataIndex: "equipment_reverse_geocoding",
      key: "equipment_reverse_geocoding",
      width: "137px",
      ellipsis: true,
    },
    {
      title: "OS Associada",
      dataIndex: "service_order",
      key: "service_order",
      width: "125px",
      render: (value, record) => <AlertOS value={value} />,
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: "status__name",
      key: "status.id",
      width: "150px",
      render: (value, record) =>
        value && (
          <BadgeOportunityStatus
            type={value.id}
            message={record.cancellation_reason}
          >
            {value.name}
          </BadgeOportunityStatus>
        ),
    },
    {
      title: "MID",
      dataIndex: "mid",
      sorter: "mid",
      key: "mid",
      width: "80px",
      ellipsis: true,
      render: (value, record) => (
        <Tooltip title={record.telemetric_data?.description}>{value}</Tooltip>
      ),
    },
    {
      title: "PID",
      dataIndex: "pid",
      sorter: "pid",
      key: "pid",
      width: "80px",
      ellipsis: true,
      render: (value, record) => (
        <Tooltip title={record.telemetric_data?.description}>{value}</Tooltip>
      ),
    },
    {
      title: "FMI",
      dataIndex: "fmi",
      sorter: "fmi",
      key: "fmi",
      width: "80px",
      ellipsis: true,
      render: (value, record) => (
        <Tooltip title={record.telemetric_data?.description}>{value}</Tooltip>
      ),
    },
    {
      title: "Resp.",
      dataIndex: "responsible",
      sorter: "responsible__name",
      key: "responsible",
      width: "100px",
      render: (responsible, record) => {
        return (
          <>
            {responsible ? (
              <AvatarProfile
                hiddenName
                iconSize={24}
                id={responsible.responsible.id}
                src={responsible.responsible.picture}
                name={responsible.responsible.get_full_name}
              />
            ) : (
              "-"
            )}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (formFilter) {
      const size = new Set(formFilter.keys()).size;
      setFilterCount(size < 2 ? 2 : size);
      handleRequestTable();
    }
    // eslint-disable-next-line
  }, [search, formFilter]);

  const handleOpportunityDetail = (record) => {
    navigate(`/opportunity/${record.id}/detail?tab=1`);
  };
  const getColumns = () => {
    let columnsDict = ListOpportunityColumnsType;
    if (equipmentId) {
      columnsDict = EquipmentOpportunityColumnsType;
    }
    if (clientId) {
      columnsDict = ClientOpportunityColumnsType;
    }

    return columns.filter((column) => {
      if (!Object.keys(columnsDict).includes(typeTable)) {
        typeTable = "all";
      }
      return columnsDict[typeTable].includes(column.dataIndex);
    });
  };
  if (!typeTable) return null;
  return (
    <UpTable
      onChange={handleTableChange}
      loading={isLoading}
      showQuickJumper
      tableHeightDiff={56}
      onRowClick={handleOpportunityDetail}
      tableParams={tableParams}
      columns={getColumns()}
      dataSource={tableData}
      scroll={{
        y: "350px",
      }}
    />
  );
}
