import React from "react";
import styled from "styled-components";
import {
  primary_color,
  primary_color_shadow_dark,
  primary_color_shadow_light,
} from "../../../Cores";
import { ReactComponent as OpportunityIcon } from "../../../assets/icons/oportunity_Icon-Wrapper.svg";
import { ReactComponent as NearOpportunityIcon } from "../../../assets/icons/near_oportunity_Icon-Wrapper.svg";
import { ReactComponent as NearOpportunitySameClientIcon } from "../../../assets/icons/near_oportunity_same_client_Icon-Wrapper.svg";
import { ReactComponent as TechIcon } from "../../../assets/icons/tech_Icon-Wrapper.svg";

import { ReactComponent as TechAvailableIcon } from "../../../assets/icons/tech_Icon_available.svg";

import { ReactComponent as TechStoppedIcon } from "../../../assets/icons/tech_Icon_stopped.svg";
import { ReactComponent as TechReturnedIcon } from "../../../assets/icons/tech_Icon_returned.svg";
import { ReactComponent as TechBusyIcon } from "../../../assets/icons/tech_Icon_busy.svg";

import { ReactComponent as PriorityOpportunityIcon } from "../../../assets/icons/priority_opportunity_icon.svg";

export const MAP_MARKER_OPPOTUNITY = "opportunity";
export const MAP_MARKER_AVAILABLE_TECHNICIAN = "tech_available";
export const MAP_MARKER_STOPPED_TECHNICIAN = "tech_stopped";
export const MAP_MARKER_RETURNED_TECHNICIAN = "tech_returned";
export const MAP_MARKER_BUSY_TECHNICIAN = "tech_busy";
export const MAP_MARKER_EQUIPMENT_YELLOW = "equipment_yellow";
export const MAP_MARKER_EQUIPMENT_GRAY = "equipment_gray";
export const MAP_MARKER_NEAR_OPPOTUNITY = "near_opportunity";
export const MAP_MARKER_PRITORITY_OPPOTUNITY = "priority_opportunity";
export const MAP_MARKER_NEAR_OPPOTUNITY_SAME_CLIENT =
  "near_opportunity_same_client";
export const MAP_MARKER_NEAR_TECHNICIAN = "near_technician";

const StyledTechIcon = styled(TechIcon)`
  ${({ markerClicked, isModalOpen }) => {
    if (markerClicked && isModalOpen) {
      return `
            path{
                fill: ${primary_color};
            }     
          `;
    }
  }}
`;
const StyledMarker = styled.div`
  transform: translateX(-100%) translateY(-100%);
  ${({ backgroundColor }) => `background: ${backgroundColor};`}

  color: black;
  width: 35px;
  position: relative;
  height: 35px;
  border-radius: 50%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;

  & > * {
    z-index: 3;
  }

  ${({
    isCluster,
    backgroundColor = primary_color,
    shadowColorLight = primary_color_shadow_light,
    shadowColorDark = primary_color_shadow_dark,
  }) => {
    if (!isCluster) {
      return `
          ::after {
            content: '';
            z-index: 1;
            background: ${backgroundColor};
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            transform: translateY(28%) rotate(45deg) scale(0.6);
          }
        `;
    }

    return `
      will-change: box-shadow;
      animation: cluster_animation${
        shadowColorLight !== primary_color_shadow_light ? "_changed" : ""
      } 2s infinite alternate-reverse;
      @keyframes cluster_animation${
        shadowColorLight !== primary_color_shadow_light ? "_changed" : ""
      }{
        from{
          box-shadow: 0 0 0 0px ${shadowColorLight},  0 0 0 0px ${shadowColorDark};
        }
        to{
          box-shadow: 0 0 0 4px ${shadowColorLight},  0 0 0 8px ${shadowColorDark};
        }
    } 
    `;
  }}

  cursor: pointer;
`;

const MarkerIconContainer = styled.div`
  transform: translateX(-100%) translateY(-100%);

  width: min-content;
  height: min-content;

  cursor: pointer;

  svg {
    //backdrop-filter: blur(1px);
  }

  ${({ markerClicked, isModalOpen }) => {
    if (markerClicked && isModalOpen) {
      return `
            path{
                fill: ${primary_color};
            }     
          `;
    }
  }}
`;

export const MapMarkerFactory = ({
  children,
  isCluester,
  type,
  object,
  properties,
  ...props
}) => {
  const _type = object?.type ? object.type : "default";
  //todo mudar cor do cluster

  const markersMap = {
    default: <StyledMarker {...props}>{children}</StyledMarker>,
    [MAP_MARKER_EQUIPMENT_YELLOW]: (
      <MarkerIconContainer {...props}>
        <OpportunityIcon>{children}</OpportunityIcon>
      </MarkerIconContainer>
    ),
    [MAP_MARKER_EQUIPMENT_GRAY]: (
      <MarkerIconContainer {...props}>
        <NearOpportunityIcon>{children}</NearOpportunityIcon>
      </MarkerIconContainer>
    ),
    [MAP_MARKER_OPPOTUNITY]: (
      <MarkerIconContainer {...props}>
        <OpportunityIcon>{children}</OpportunityIcon>
      </MarkerIconContainer>
    ),
    [MAP_MARKER_NEAR_OPPOTUNITY]: (
      <MarkerIconContainer {...props}>
        <NearOpportunityIcon>{children}</NearOpportunityIcon>
      </MarkerIconContainer>
    ),
    [MAP_MARKER_NEAR_OPPOTUNITY_SAME_CLIENT]: (
      <MarkerIconContainer {...props}>
        <NearOpportunitySameClientIcon>
          {children}
        </NearOpportunitySameClientIcon>
      </MarkerIconContainer>
    ),
    [MAP_MARKER_NEAR_TECHNICIAN]: (
      <MarkerIconContainer {...props}>
        <StyledTechIcon status={object?.status} />
      </MarkerIconContainer>
    ),
    [MAP_MARKER_PRITORITY_OPPOTUNITY]: (
      <MarkerIconContainer {...props}>
        <PriorityOpportunityIcon status={object?.status} />
      </MarkerIconContainer>
    ),
    [MAP_MARKER_AVAILABLE_TECHNICIAN]: (
      <MarkerIconContainer {...props}>
        <TechAvailableIcon status={object?.status} />
      </MarkerIconContainer>
    ),
    [MAP_MARKER_STOPPED_TECHNICIAN]: (
      <MarkerIconContainer {...props}>
        <TechStoppedIcon status={object?.status} />
      </MarkerIconContainer>
    ),
    [MAP_MARKER_RETURNED_TECHNICIAN]: (
      <MarkerIconContainer {...props}>
        <TechReturnedIcon status={object?.status} />
      </MarkerIconContainer>
    ),
    [MAP_MARKER_BUSY_TECHNICIAN]: (
      <MarkerIconContainer {...props}>
        <TechBusyIcon status={object?.status} />
      </MarkerIconContainer>
    ),
  };
  return markersMap[_type];
};
